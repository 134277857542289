<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <div class="header_section">
                    <h4 class="menu_header">Loyalty/GiftVoucher Settings</h4>
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <form @submit.prevent="createVoucherSettings">
                    <div class="row">
                      <div class="col-md-12" v-if="errors ">
                        <div class="alert alert-danger">
                          <ul>
                            <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                  
                      <div class="col-lg-6">
                        <div class="setting_content">
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Voucher Enable:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="enableVoucher" :value="1" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="enableVoucher" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Days Remaining To Send Expiry Notification:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="notificationsDay">
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Allow Voucher Approval:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="allowVoucherApproval" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="allowVoucherApproval" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Allow Multiple Print Urls:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="allowMultipleUrls" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="allowMultipleUrls" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="email">Setup:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input v-model="voucherSetup" type="radio" :value="1" checked>&nbsp; Live </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input v-model="voucherSetup" type="radio" name="optradio" :value="0"> &nbsp; Demo</label>
                              </div>
                            </div>
                          </div>

                          <!--  -->
                          <div v-if="voucherSetup">
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="lv_giftvoucher_url">Live  Url:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="liveVoucherUrl" placeholder="Enter GiftVoucher Url">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="lv_giftvoucher_username">Live  Username:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="liveVoucherUsername" placeholder="Enter GiftVoucher Username">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="lv_giftvoucher_password">Live Password:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="liveVoucherPassword" placeholder="Enter GiftVoucher Password">
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="!voucherSetup">
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_giftvoucher_url">Demo  Url:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="demoVoucherUrl" placeholder="Enter GiftVoucher Url">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_giftvoucher_username">Demo Username:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="demoVoucherUsername" placeholder="Enter GiftVoucher Username" name="email">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_giftvoucher_password">Demo Password:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="demoVoucherPassword" placeholder="Enter GiftVoucher Password" name="email">
                                </div>
                              </div>
                            </div>

                          </div>
                          <!--  -->
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Support Email:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="supportEmail" placeholder="Enter Support Email">
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Embedded Url: </label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="embeddedUrl" placeholder="Enter Widget Embedded Url">
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Payment Api Setup: </label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="paymentSetup" :value="1">&nbsp; Live </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="paymentSetup" :value="0"> &nbsp; Demo</label>
                          
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Pesapal Secret: </label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="pesapalSecret" placeholder="Enter Pesapal Secret">
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Pesapal Key: </label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="pesapalKey" placeholder="Enter Pesapal Key">
                              </div>
                            </div>
                          </div>



                          <div class="form-group setting_form_grp" v-if="allowVoucherApproval">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="dm_consumer_secret"> Approvers Email:</label>
                              <div class="col-sm-7">
                                <input type="text" class="form-control" v-model="approvalEmails" placeholder="Enter Approvers Email">
                              </div>
                            </div>
                          </div>
                          
                      
                        </div>

                        <hr>

                      </div>
                      <!--  -->
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group setting_form_grp mt-4">
                          
                          <label>Coupon Template</label>
                          <textarea class="form-control" rows="20" cols="600" v-model="couponTempalate"></textarea>
                          <!-- <div class="">
                        <editor
                          v-model="couponTempalate"
                          :init ="{
                            selector: 'textarea',
                            format: 'raw',
                            height: 500,
                            editor_encoding : 'raw',
                            branding: false
                          }"
                        />
                      </div> -->
                          
                        
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group setting_form_grp">
                          <div v-if="preview" v-html="cleantHtml(couponTempalate)"></div>
                          <button type="button" @click="previewContent" class="btn btn-light"><i class="fas fa-eye"></i> Preview Coupon template</button>
                        </div>
                    
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button type="submit"  class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save Changes</span></button>
                      </div>
                    </div>
                    <!-- end row -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
// import { JSDOM } from 'jsdom';
import DOMPurify from 'isomorphic-dompurify';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
// import Editor from '@tinymce/tinymce-vue';
export default {
   components: {
        // Editor
    },
    data() {
        return {
            loading:false,
            errors: false,
            errorMsg: [],
            voucherSetup: 1,
            spinner: false,
            enableVoucher: 1,
            liveVoucherUrl: '',
            liveVoucherUsername: '',
            liveVoucherPassword: '',
            demoVoucherUrl: '',
            demoVoucherUsername: '',
            demoVoucherPassword: '',
            allowVoucherApproval:0,
            supportEmail: '',
            preview:false,
            notificationsDay:10,
            couponTempalate:'',
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes...",
            allowMultipleUrls: 0,
            embeddedUrl:'',
            paymentSetup: 0,
            pesapalSecret:'',
            pesapalKey:''

        }
    },
    mounted() {
        this.getVoucherSettings();
    },
    methods: {
        cleantHtml(text){
          const clean = DOMPurify.sanitize(text);
          return clean
        },
        async getVoucherSettings() {
          this.loading = true;
          const loader = document.getElementById('main-loader');
          loader.style.display = 'block';
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/configs/" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200 && response.data.data) {
                    this.voucherSetup = response.data.data.giftvoucher_settings.voucher_setup !==''?response.data.data.giftvoucher_settings.voucher_setup:1;
                    this.enableVoucher = response.data.data.giftvoucher_settings.enable_voucher !==''?response.data.data.giftvoucher_settings.enable_voucher:1;
                    this.liveVoucherUrl = response.data.data.giftvoucher_settings.live_api_url !==''?response.data.data.giftvoucher_settings.live_api_url:'';
                    this.liveVoucherUsername = response.data.data.giftvoucher_settings.live_api_username !==''?response.data.data.giftvoucher_settings.live_api_username:'';
                    this.liveVoucherPassword = response.data.data.giftvoucher_settings.live_api_password !==''?response.data.data.giftvoucher_settings.live_api_password:'';
                    this.demoVoucherUrl = response.data.data.giftvoucher_settings.demo_api_url!==''?response.data.data.giftvoucher_settings.demo_api_url:'';
                    this.demoVoucherUsername = response.data.data.giftvoucher_settings.demo_api_username !==''?response.data.data.giftvoucher_settings.demo_api_username:'';
                    this.demoVoucherPassword = response.data.data.giftvoucher_settings.demo_api_password !==''?response.data.data.giftvoucher_settings.demo_api_password:'';
                    this.supportEmail = response.data.data.giftvoucher_settings.support_email !==''?response.data.data.giftvoucher_settings.support_email:'';
                    this.couponTempalate = response.data.data.giftvoucher_settings.coupon_template !=='' ? response.data.data.giftvoucher_settings.coupon_template : '';
                    this.allowVoucherApproval = response.data.data.giftvoucher_settings.approval_required
                    this.approvalEmails = response.data.data.giftvoucher_settings.approval_emails
                    this.allowMultipleUrls = response.data.data.giftvoucher_settings.allow_multiple_urls
                    this.notificationsDay = response.data.data.giftvoucher_settings.notification_day
                    this.embeddedUrl = response.data.data.giftvoucher_settings.embbeded_url ? response.data.data.giftvoucher_settings.embbeded_url :''
                    this.pesapalSecret = response.data.data.giftvoucher_settings.pesapal_secret ? response.data.data.giftvoucher_settings.pesapal_secret : ''
                    this.pesapalKey = response.data.data.giftvoucher_settings.pesapal_key ? response.data.data.giftvoucher_settings.pesapal_key : ''
                    this.paymentSetup = response.data.data.giftvoucher_settings.payment_setup ? response.data.data.giftvoucher_settings.payment_setup : 0
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            }).finally(() => {
              this.loading = false;
              loader.style.display = 'none';
            });
        },
        async createVoucherSettings() {
            this.spinner = true;
            this.errors = false;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/create-giftcard-settings";
            const payload = {
                'merchant_id': parseInt(this.merchantID),
                'voucher_setup': this.voucherSetup,
                'enable_voucher': this.enableVoucher,
                'live_api_url': this.liveVoucherUrl,
                'live_api_username':this.liveVoucherUsername,
                'live_api_password': this.liveVoucherPassword,
                'demo_api_url': this.demoVoucherUrl,
                'coupon_template': this.cleantHtml(this.couponTempalate),
                'demo_api_username': this.demoVoucherUsername,
                'demo_api_password': this.demoVoucherPassword,
                'support_email':this.supportEmail,
                'approval_required': this.allowVoucherApproval,
                'approval_emails': this.approvalEmails,
                'allow_multiple_urls': this.allowMultipleUrls,
                'notification_day':this.notificationsDay,
                'embbeded_url':this.embeddedUrl,
                'pesapal_secret':this.pesapalSecret,
                'pesapal_key':this.pesapalKey,
                'payment_setup':this.paymentSetup
            }
            this.axios.post(url,payload,config).then((response)=>{
                if(response.status ===200){
                    this.getVoucherSettings();
                    toast.success('settings changes was successfully updated');
                }
            }).catch((error)=>{
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                }
                if (error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
            }).finally(()=> this.spinner = false);

        },
        previewContent(){
          this.preview = !this.preview;
        },
    },
    mixins: [ReusableDataMixins]
}
</script>
